/**
 * Created by vaibhav on 31/3/18
 */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import IRPageWrapper from "../../layouts/IRPageWrapper"
import Q1 from "./qimg/q1.png"
import Q2 from "./qimg/q2.png"
import Q3 from "./qimg/q3.png"
import Q4 from "./qimg/q4.png"

import IRNav from "../../components/IRNav"
import IRBG from "../../components/IRBG"
import HomeLogo from "../../components/HomeLogo"

export default class QuarterPage extends Component {
  render() {
    const { edges } = this.props.data.allMarkdownRemark
    return (
      <IRPageWrapper>
        <IRBG />
        <Helmet>
          <title>Quarter - EA Technique</title>
        </Helmet>
        <section className="hero is-small">
          <div className="columns">
            <div className="column is-offset-1">
              <div className="section">
                <HomeLogo />
                <h1 className="title" id="title-line">
                  Quarterly Reports
                </h1>
                <IRNav />
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="column is-offset-1">
            <div className="columns is-multiline">
              {edges.map((node, index) => (
                <div
                  className={`column is-2 ${index % 4 != 0 && `is-offset-1`}`}
                  key={index}
                >
                  <div className="title">{node.node.frontmatter.year}</div>
                  <div className="columns is-multiline is-mobile">
                    {node.node.frontmatter.quarters.map((quarter, index1) => (
                      <div className="column is-5-mobile is-6" key={index1}>
                        <a href={quarter.manual_pdf}>
                          <img src={quarter.quarter_image} />
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </IRPageWrapper>
    )
  }
}

QuarterPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export const quarterReportPageQuery = graphql`
  query QuarterReportPage {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "quarter-page" } } }
      sort: { order: ASC, fields: [frontmatter___year] }
    ) {
      edges {
        node {
          frontmatter {
            year
            quarters {
              quarter_number
              quarter_image
              manual_pdf
            }
          }
        }
      }
    }
  }
`
